import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 全局引入样式文件 tailwindcss
import '@/assets/output.css'
// 动画
import '@/assets/css/animation.css'
// 阿里图标
import '@/assets/icon/ali_iconfont.css'

const app = createApp(App);

app.use(router)
    .use(store)
    .use(ElementPlus)
    .mount('#app')
