const state = {
    // 屏幕宽度
    resize: 0
}
const mutations = {
    // 设置屏幕宽度
    SET_RESIZE(state, resize) {
        state.resize = resize
    }
}
const actions = {}
const getters = {
    // 屏幕宽度
    resize: state => state.resize
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
