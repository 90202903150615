// src/store/index.js
import {createStore} from 'vuex';

import screen from "@/store/screen";

export default createStore({
    modules: {
        // 屏幕
        screen
    }
});
