<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script setup>
</script>
<style scoped>
.app {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
}
</style>
<style>
@import "@/assets/css/global.css";
@import "@/assets/css/root.css";

</style>
